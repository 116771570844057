define("client/pods/ui/entry/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['where'],
    where: null,
    init: function init() {
      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});