define("client/pods/ui/entries/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['currentPage', 'entryGroup', 'filters', 'listingType', 'rp', 'sortColumn', 'sortOrder', 'where'],
    currentPage: '1',
    entryGroup: null,
    listingType: null,
    rp: null,
    sortColumn: null,
    sortOrder: 'ASC',
    where: null,
    init: function init() {
      this._super.apply(this, arguments);

      this.filters = [];
    }
  });

  _exports.default = _default;
});